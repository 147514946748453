@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,div,dl,dt,dd,ul,ol,li,pre,
form,fieldset,input,textarea,th,td {
    padding: 0;
    margin: 0;
}
a{
	text-decoration:none;
}
table {
    border-spacing: 0;
}
fieldset,img {
    border: 0;
}

strong{
	font-weight: bold;
}

caption,th {
    text-align: left;

}

q:before,q:after {
    content:'';
}
abbr,acronym { border: 0;
}


/* Custom CSS
 * --------------------------------------- */

.wrap{
	margin-left: auto;
	margin-right: auto;
	width: 960px;
	position: relative;
}

.intro p{
	width: 50%;
	margin: 0 auto;
	font-size: 1.5em;
}
.section{
	text-align:center;
}
#menu li {
	display:inline-block;
	margin: 10px;
	color: #000;
	background:#fff;
	background: rgba(255,255,255, 0.5);
	-webkit-border-radius: 10px;
            border-radius: 10px;
}
#menu li.active{
	background:#666;
	background: rgba(0,0,0, 0.5);
	color: #fff;
}
#menu li a{
	text-decoration:none;
	color: #000;
}
#menu li.active a:hover{
	color: #000;
}
#menu li:hover{
	background: rgba(255,255,255, 0.8);
}
#menu li a,
#menu li.active a{
	padding: 9px 18px;
	display:block;
}
#menu li.active a{
	color: #fff;
}
#menu{
	position:fixed;
	top:0;
	left:0;
	height: 40px;
	z-index: 70;
	width: 100%;
	padding: 0;
	margin:0;
}
.twitter-share-button{
	position: fixed !important;
	z-index: 99;
	right: 149px;
	top: 9px;
}
#infoMenu{
	height: 20px;
	color: #f2f2f2;
	position:fixed;
	z-index:70;
	bottom:0;
	width:100%;
	text-align:right;
	font-size:0.9em;
	padding:8px 0 8px 0;
}
#infoMenu ul{
	padding: 0 40px;
}
#infoMenu li a{
	display: block;
	margin: 0 22px 0 0;
	color: #333;
}
#infoMenu li a:hover{
	text-decoration:underline;
}
#infoMenu li{
	display:inline-block;
	position:relative;
}
#examplesList{
	display:none;
	background: #282828;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	padding: 20px;
	float: left;
	position: absolute;
	bottom: 29px;
	right: 0;
	width:822px;
	text-align:left;
}
#examplesList ul{
	padding:0;
}
#examplesList ul li{
	display:block;
	margin: 5px 0;
}
#examplesList ul li a{
	color: #BDBDBD;
	margin:0;
}
#examplesList ul li a:hover{
	color: #f2f2f2;
}
#examplesList .column{
	float: left;
	margin: 0 20px 0 0;
}
#examplesList h3{
	color: #f2f2f2;
	font-size: 1.2em;
	margin: 0 0 15px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
	-moz-box-shadow: 0 1px 0 rgba(255,255,255,0.1);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
	padding: 0 0 5px 0;
}



/* Demos Menu
 * --------------------------------------- */
 #demosMenu{
	position:fixed;
	bottom: 10px;
	right:10px;
	z-index: 999;
 }

/* Buttons
 * --------------------------------------- */
#download a{
    text-decoration: none
}
#download,
#download:hover {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25)
}
.button {
    margin: 20px 0 0 0;
    vertical-align: middle;
    display: inline-block
}

.button a {
    color: #fff;
    background: rgba(0,0,0,0.4);
    padding: 15px 30px;
    border-radius: 5px;
    display: inline-block;
}

.button a:hover {
    color: #316f68;
    background-color: #fff
}


/* Extensions preview menu
 * --------------------------------------- */
.preview-extensions-menu{
	position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    border-top: 0;
    box-shadow: 1px 0px 3px rgba(0,0,0,0.8);
}
.preview-extensions-menu li{
	display: inline-block;
	margin: 0;
}
.preview-extensions-menu li a{
	padding: 15px 25px;
	display: block;
}
.preview-buy-extension{
    background: #3cc63c;
}
.preview-more-extensions{
	background: #fff;
}
.preview-buy-extension a{
	color: #fff;
}
.preview-buy-extension:hover{
	background-color: #1a961a;
}
.preview-more-extensions a{
	color: #333;
}

.code{
	background: rgba(255,255,255,0.7);
	padding: 0px 5px;
	line-height: 25px;
}


/* Style for our header texts
* --------------------------------------- */
.section p,
.intro p{
	color: #fff;
}

/* Centered texts in each section
* --------------------------------------- */
.section{
	text-align:center;
}


/* Bottom menu
* --------------------------------------- */
#infoMenu li a {
	color: #fff;
}

/* Sections backgrounds
* --------------------------------------- */
#section0{
	background-image: linear-gradient(128deg,#40afff,#3f61ff);
}

#section1{
	background-image: linear-gradient(128deg,#ff9a3f,#ff4b40);
}

#section2{
	background-image: linear-gradient(128deg,#fc40ff,#543fff);
}

#section3{
	background-image: linear-gradient(128deg,#40fff2,#3fbcff);
}