h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
  color:rgb(255, 255, 255);
  margin-bottom: 2rem;
}

.App {
  text-align: center;
  background-color: #2a2b2e;
  font-size: calc(10px + 1.5vmin);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.quote {
  margin-left: 20rem;
  margin-right: 20rem;
  text-align: left;
  color: white;
}

blockquote {
  margin: 0;
  padding: 0;
}

figcaption {
  margin-top: 0.5rem;
  margin-left: 3rem;
}

cite {
  font-style: italic;
}

p {
  margin-top: 0.5rem;
}

.slide-content {
  margin-left: 5rem;
  margin-right: 5rem;
}

ol,ul {
  list-style: none;
  margin:0;
  padding:0;
  color: white;
}

table {
  color: white;
  text-align: left;
}

.address-cell p {
  margin: 0;
  padding: 0;
}

.address-cell {
  padding-top: 2rem;
  vertical-align: top;
}